
import LoginComponent from '@/components/landingAndRegistrationComponents/LoginComponent.vue';
import BaseButton from '@/components/landingAndRegistrationComponents/BaseButtonLanding.vue';
import { getNextPage } from '@/utils/redirectUtils';
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
  components: { BaseButton, LoginComponent },
  computed: {
    ...mapState('calculateWindowWidth', ['windowWidth']),
  },
})
export default class Landing360Page extends Vue {
  public windowWidth!: number;

  benefits = [
    {
      img: 'insights',
      title: 'Actionable insights empower your business growth',
      text: 'Assess your small business health and access actionable insights from your favorite business apps, with 160+ app integrations. Data is aggregated in real-time and cross-analyzed to better understand current issues facing your business, and how to resolve them. These highly tailored insights give your business a live playbook as to your next steps towards greater profitability.',
      id: 1,
    },
    {
      img: 'alerts',
      title: 'Performance alerts',
      text: 'How much cash can your business expect to generate, and how much will be leftover after expenses? How do different financial, human resources, and operational decisions affect future cash flows? Real-time activity alerts offer immediate support with further drill-downs into issue analysis, recommendations and action steps to help your small business scale faster.',
    },
    {
      img: 'intelligent',
      title: 'Intelligent forecasting',
      text: 'An intelligent forecast modeling engine helps you understand how different decisions in future sales, marketing, HR, operations, and finance issues influence your business outcomes and profitability.',
    },
    {
      img: 'boost',
      title: 'Manage your business credit score and boost it',
      text: 'CreditPush gives you actionable insights to help your credit score improve, making any credit application more likely to be approved.',
    },
  ];

  get diagramSrc(): string {
    if (this.windowWidth < 1280 && this.windowWidth >= 764) return '_tablet';
    if (this.windowWidth > 0 && this.windowWidth < 764) return '_mobile';
    return '';
  }

  goToRegistration(): void {
    this.$router.push({ name: getNextPage() });
  }

  mounted(): void {
    this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion: '302', pageName: this.$route.name });
  }
}
